<template>
  <div v-if="showPopup && !isStandalone" class="popup">
    <div class="popup-content">
      <span class="close" @click="closePopup">&times;</span>
      <div class="popup-title">请保存我们的网页在桌面，永不迷路</div>
      <div>已safari浏览器为例 其他浏览器类似</div>
      第一步（打开浏览器下方菜单）
      <img class="ios-img" src="./assets/ios1.jpg" alt="ios1" />
      第二步（点击添加到主屏幕）
      <img class="ios-img" src="./assets/ios2.jpg" alt="ios2" />

      <div class="noMorePopup">
        <input type="checkbox" id="noMorePopup" v-model="noMorePopup" />
        <label for="noMorePopup">下次不再提示</label>
      </div>
    </div>
  </div>
  <iframe
    v-if="iframeLoaded"
    class="full-screen-iframe"
    :src="iframeSrc"
  ></iframe>
</template>

<script>
import { db } from "./db";

export default {
  data() {
    return {
      showPopup: !localStorage.getItem("noMorePopup"),
      noMorePopup: false,
      iframeSrc: "",
      iframeLoaded: false,
    };
  },
  computed: {
    isStandalone() {
      return (
        window.matchMedia("(display-mode: standalone)").matches || // Android Chrome
        window.navigator.standalone || // iOS Safari
        document.referrer.includes("android-app://")
      ); // Android WebView
    },
  },
  methods: {
    closePopup() {
      this.showPopup = false;
      if (this.noMorePopup) {
        localStorage.setItem("noMorePopup", "true");
      }
    },
    async getIframeSrc() {
      const baseUrl = "https://www.sxfdsfsdbvbx.xin/Chat/Chat";
      let params = new URLSearchParams(window.location.search);
      console.log(params.toString());
      if (!params.toString()) {
        const lastParams = await db.getItem("sx_params");
        console.log("lastParams", lastParams);
        if (lastParams) {
          params = new URLSearchParams(lastParams);
        } else {
          params.append(
            "userID",
            Math.floor(Math.random() * 100000000)
              .toString()
              .padStart(8, "0")
          );
          params.append("userName", "官网来源");
          await db.setItem("sx_params", params.toString());
        }
      } else {
        await db.setItem("sx_params", params.toString());
      }
      console.log(`${baseUrl}?${params.toString()}`);
      this.iframeSrc = `${baseUrl}?${params.toString()}`;
      this.iframeLoaded = true;
    },
  },
  watch: {
    noMorePopup(newVal) {
      if (newVal) {
        localStorage.setItem("noMorePopup", "true");
      } else {
        localStorage.removeItem("noMorePopup");
      }
    },
  },
  mounted() {
    this.getIframeSrc();
  },
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.full-screen-iframe {
  width: 100%;
  height: 100vh;
  border: none;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  width: 300px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.popup-title {
  font-size: 15px;
  font-weight: bold;
}

.close {
  position: absolute;
  top: -10px;
  right: 3px;
  cursor: pointer;
  font-size: 30px;
  color: #ec1919;
}

.noMorePopup {
  margin-top: 20px;
}

.ios-img {
  width: 100%;
  height: 100%;
}
</style>
