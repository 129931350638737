// src/utils/db.js
class CookieService {
    constructor() {
      this.cookieName = 'ShoxinKFSettings';  // Cookie 名称
    }

    // 设置 Cookie
    setCookie(name, value, days) {
      let expires = "";
      if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }

    // 获取 Cookie
    getCookie(name) {
      let nameEQ = name + "=";
      let ca = document.cookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    }

    // 删除 Cookie
    eraseCookie(name) {
      document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }

    // 保存数据
    async setItem(key, value) {
      this.setCookie(key, value, 365); // 存储一年
      return Promise.resolve();
    }

    // 获取数据
    async getItem(key) {
      const result = this.getCookie(key);
      return Promise.resolve(result);
    }

    // 删除数据
    async removeItem(key) {
      this.eraseCookie(key);
      return Promise.resolve();
    }
}

export const db = new CookieService();
